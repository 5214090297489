import React from 'react';
import Soon from './components/soon'

function App() {
  return (
    <div className="App">
      <Soon />
    </div>
  );
}

export default App;
