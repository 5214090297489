import React, { useEffect, useState } from 'react';
import '../css/main.css';

function Soon() {
  const [fontSize, setFontSize] = useState(() => {
    const calculate = () => {
      const min = Math.min(window.innerWidth, window.innerHeight);
      return min * 0.15;
    };
    return `${calculate()}px`;
  });

  useEffect(() => {
    const resize = () => {
      const min = Math.min(window.innerWidth, window.innerHeight);
      setFontSize(`${min * 0.15}px`);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <div className="soon">
      <h1 className="text" style={{ fontSize }}>Soon</h1>
    </div>
  );
}

export default Soon;
